import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  CalendarOptions,
  DateSpanApi,
  EventAddArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
  EventRemoveArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {
  DateClickArg,
  Draggable,
  DropArg,
} from '@fullcalendar/interaction';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OutGetV1StaffsDTO } from '../../../../../../backend/dist/services/UserService';
import { StaffDialogComponent } from '../../../shared/staff-dialog/staff-dialog.component';
import { attendService, leaveService } from '../../../../sdk';
import { FullCalendarComponent } from '@fullcalendar/angular';
import * as moment from 'moment';
import { EventImpl } from '@fullcalendar/core/internal';
import { AuthService } from '../../../../app/auth/auth.service';
import { FormDialogComponent } from '../../../shared/form-dialog/form-dialog.component';
import { InPostV1RecordDTO } from '../../../../../../backend/dist/services/LeaveService';
import { LeaveType } from '../../../../../../backend/dist/constants/enum';
import { LeaveCreateDialogComponent } from '../../leave/leave-create-dialog/leave-create-dialog.component';

@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss'],
})
export class ShiftComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendar!: FullCalendarComponent;

  @ViewChild('externalEvents')
  set externalEvents(value: { nativeElement: HTMLElement }) {
    // initializeDraggableEvents
    let draggableEl = value.nativeElement;
    console.log('externalEvents', draggableEl);

    new Draggable(draggableEl, {
      itemSelector: '.fc-event',
      eventData: function (eventEl: any) {
        return {
          title: eventEl.innerText,
        };
      },
    });
  }

  year = new Date().getFullYear()
  month = new Date().getMonth() + 1

  eventColor = {
    REJECTED: '#ff006e',
    PENDING: '#ffbe0b',
    ACCEPTED: '#2ec4b6',
  };

  constructor(
    private modalService: NzModalService,
    public authService: AuthService
  ) {
    if (this.authService.isAdmin()) {
      this.calendarOptions = {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth',
        },
        droppable: true,
        editable: true,
        drop: this.handleEventDrop.bind(this),
        dateClick: this.handleDateClick.bind(this),
        eventAdd: this.handleEventAdd.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventAllow: this.handleEventAllow.bind(this),
        eventChange: this.handleEventChange.bind(this),
        eventRemove: this.handleEventRemove.bind(this),
      };
    } else {
      this.calendarOptions = {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth',
        },
        droppable: false,
        editable: false,
        eventClick: this.eventClickApplyLeave.bind(this),
      };
    }
  }

  currentStaff = {
    id: NaN,
    name: '',
    department: '',
  };

  events: EventInput[] = [];

  calendarOptions: CalendarOptions;

  async ngOnInit() {
    console.log('test', this.externalEvents);
  }

  handleEventAllow(span: DateSpanApi, movingEvent: EventImpl | null): boolean {
    if (movingEvent == null) {
      return true;
    } else {
      return span.start > new Date();
    }
  }

  handleEventClick(args: EventClickArg) {
    args.event.remove();
  }

  handleDateClick(arg: DateClickArg) {}

  handleEventDrop(arg: DropArg) {}

  onSelect() {
    const dialogRef = this.modalService.create({
      nzTitle: 'Staff',
      nzWidth: '300vh',
      nzContent: StaffDialogComponent,
      nzFooter: null, // 隱藏默認的對話框按鈕
      nzComponentParams: {
        multiChoice: false,
      },
    });

    dialogRef.afterClose.subscribe(
      async (selectedStaff: OutGetV1StaffsDTO[]) => {
        if (selectedStaff.length == 1) {
          this.currentStaff.id = selectedStaff[0].id;
          this.currentStaff.name = selectedStaff[0].username;
          this.currentStaff.department = selectedStaff[0].department;

          const res = await attendService.getV1Schedule({
            id: selectedStaff[0].id,
          });

          res.data.forEach((ele) => {
            this.calendar
              .getApi()
              .addEvent({ start: ele.dateISO, title: ele.status });
          });

          const r = await leaveService.getV1Records({
            user_id: selectedStaff[0].id,
          });

          r.data.forEach((ele) => {
            this.calendar.getApi().addEvent({
              start: ele.leave_date,
              title: 'Leave :' + ele.status_description,
              borderColor:
                this.eventColor[
                  ele.status_description as keyof typeof this.eventColor
                ],
              backgroundColor:
                this.eventColor[
                  ele.status_description as keyof typeof this.eventColor
                ],
            });
          });
        }
      }
    );
  }
  handleEventAdd(arg: EventAddArg) {
    // const date = moment(arg.event.start).format("YYYY-MM-DD")
    //
    // this.events.push({
    //   title: arg.event.title,
    //   start: date
    // })
    //
    // this.fetchEvents()
  }

  handleEventChange(arg: EventChangeArg) {
    // const oldDate = moment(arg.oldEvent.start).format("YYYY-MM-DD")
    // const date = moment(arg.event.start).format("YYYY-MM-DD")
    //
    // this.events.map((ele) => {
    //
    //   if(ele.date == oldDate){
    //
    //     return {
    //       title: arg.event.title,
    //       start: date
    //     }
    //   } else {
    //
    //     return ele
    //   }
    // })
  }

  handleEventRemove(arg: EventRemoveArg) {
    // const date = moment(arg.event.start).format("YYYY-MM-DD")
    //
    // this.events = this.events.filter((arr)=>{
    //
    //   return arr.start != date
    // })
    //
    // this.fetchEvents()
  }

  isNaN(obj: number) {
    return isNaN(obj);
  }

  eventClickApplyLeave(event: EventClickArg) {

    console.log("clicked")

    if (event.event.title == 'SCHEDULED') {
      const res: InPostV1RecordDTO = {
        leave_date: moment(event.event.start).format('YYYY-MM-DD'),
        leave_type: LeaveType.ANNUAL_LEAVE,
        reason: '',
        user_id: this.authService.getCredentials()?.id!,
      };

      const dialogRef = this.modalService.create({
        nzTitle: '休假申請',
        nzWidth: '100vh',
        nzContent: LeaveCreateDialogComponent,
        nzFooter: null,
        nzComponentParams: {
          defaultInputField: res,
          listOfField: [
            { formId: 'leave_date', formField: '休假日期', required: true },
            { formId: 'reason', formField: '休假原因', required: true },
          ],
        },
      });
      dialogRef.afterClose.subscribe(async (ele: InPostV1RecordDTO) => {
        if (ele) {
          await leaveService.postV1Record(ele);
          await this.ngOnInit();
        }
      });
    }
  }

  async onSubmit() {
    const date: string[] = [];

    this.calendar
      .getApi()
      .getEvents()
      .forEach((event) => {
        date.push(moment(event.start).format('YYYY-MM-DD'));
      });

    attendService
      .putV1Schedule({
        onDuty_ISODate: date,
        user_id: this.currentStaff.id,
      })
      .then((ele) => {
        console.log(ele);
        alert('排班成功 !');
      })
      .catch((err) => {
        alert(String(err));
      });
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.authService.isAdmin()) {
      const res = await attendService.getV1Schedule({
        id: this.authService.getCredentials()?.id!,
      });

      res.data.forEach((ele) => {
        this.calendar.getApi().addEvent({
          start: ele.dateISO,
          title: ele.status,
        });
      });

      const r = await leaveService.getV1Records({
        user_id: this.authService.getCredentials()?.id!,
      });

      r.data.forEach((ele) => {
        this.calendar.getApi().addEvent({
          start: ele.leave_date,
          title: 'Leave :' + ele.status_description,
          borderColor:
            this.eventColor[
              ele.status_description as keyof typeof this.eventColor
            ],
          backgroundColor:
            this.eventColor[
              ele.status_description as keyof typeof this.eventColor
            ],
        });
      });
    } else {
      const res = await attendService.getV1Schedule({})
      res.data.forEach((ele) => {
        this.calendar.getApi().addEvent({
          start: ele.dateISO,
          title: ele.status,
        });
      });

      console.log("res",res)

      const r = await leaveService.getV1Records({});

      r.data.forEach((ele) => {
        this.calendar.getApi().addEvent({
          start: ele.leave_date,
          title: 'Leave :' + ele.status_description,
          borderColor:
            this.eventColor[
              ele.status_description as keyof typeof this.eventColor
            ],
          backgroundColor:
            this.eventColor[
              ele.status_description as keyof typeof this.eventColor
            ],
        });
      });
    }
  }
}
